import {get} from 'lodash'
import mg from './mg.yml'
import sk from './sk.yml'
import en from './en.yml'
import de from './de.yml'
import cs from './cs.yml'


// Parity with backend/src/api/landing-page/content-types/landing-page/schema.json
export const SK = 'SK'
export const MG = 'MG'
export const EN = 'EN'
export const DE = 'DE'
export const CS = 'CS'

const translations = {
  [SK]: sk,
  [MG]: mg,
  [EN]: en,
  [DE]: de,
  [CS]: cs,
}

export const t = (lang = SK, key) => {
  const translation = get(translations, [lang, key], undefined)
  if (!translation) {
    return key
  }
  return translation
}
